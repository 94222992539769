const headers = [
  {
    text: 'Icon',
    value: 'icon',
  },
  {
    text: 'Type',
    value: 'friendly',
  },
  {
    text: 'Name',
    value: 'algCode',
  },
  {
    text: 'Status',
    value: 'status',
  },
  {
    text: 'Enrolled Date',
    value: 'enrollTimestamp',
  },
  {
    text: 'Files',
    value: 'files',
    /* eslint consistent-return: "off" */
    format: (item, index, createElement) => {
      if (item.hasFiles) {
        return createElement('v-icon', {}, 'attachment');
      }
    },
  },
];

export default headers;
