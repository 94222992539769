<template>
  <div>

    <BaseTableHeader
      @searchChange="searchChange"
      @refresh="refresh"
    />

    <BaseTable
      :headers="headers"
      :items="enrollments"
      :search="search"
      rowKey="algCode"
      @rowClick="rowClick"
    />

  </div>
</template>

<script>
import { headers } from '@/components/config/userEnrollments';
import baseTableMixin from '@/components/mixins/workspace/baseTableMixin';

export default {
  name: 'UserEnrollmentTable',
  mixins: [baseTableMixin],
  props: {
    enrollments: Array,
    tenantBiometrics: Array,
  },
  data: () => ({
    headers,
  }),
  mounted() {
    const iconHeader = headers.find(header => header.value === 'icon');

    if (iconHeader) {
      iconHeader.format = (item, index, createElement) => (
        createElement('v-icon', item[index])
      );
    }
  },
  watch: {
    enrollments(enrollments) {
      const vm = this;
      enrollments.forEach((item, index) => {
        // Filter documents by the given enrollment type.
        if (Array.isArray(item.files) && item.files.length > 0) {
          // Add the count to the enrollment
          vm.enrollments[index].hasFiles = item.files.length > 0;
        } else {
          vm.enrollments[index].hasFiles = false;
        }
      });
    },
  },
};
</script>
